<template>
	<div id="aswwqa">
		<el-breadcrumb separator-class="el-icon-arrow-right" class="headermunt">
			<el-breadcrumb-item :to="{ path: 'page3' }">方案管理</el-breadcrumb-item>
			<el-breadcrumb-item>新增方案设备</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- <div class="formdatas">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="方案名称" prop="poName">
					<el-input v-model="ruleForm.poName"></el-input>
				</el-form-item>
				<el-form-item label="方案简介" prop="poContext">
					<el-input type="textarea" :rows="10" v-model="ruleForm.poContext"></el-input>
				</el-form-item>
			</el-form>
		</div> -->
		<div class="add" v-loading="loadings">
			<div :class="[$route.query.version ? 'version versions':'version']">
				<span v-if="$route.query.version">当前版本：{{$route.query.version}}</span>
				<el-button v-if="$route.query.isUp!=3" type="primary" @click="opendialog">添加设备</el-button>
			</div>
			<el-table ref="overtable" v-if="tableData&&tableData.length>0" height="500" border :data="tableData"
				style="width: 100%" @selection-change="programmeselection">
				<el-table-column v-if="$route.query.isUp!=3" type="selection" width="55">
				</el-table-column>
				<el-table-column type="index" label="序号" width="50">
				</el-table-column>
				<el-table-column type="expand">
					<template slot-scope="props">
						<div>是否订做：{{ props.row.eqCustomMade }}</div>
						<div>发票、税：{{ props.row.eqInvoice }}</div>
						<div>是否安装/安装费：{{ props.row.eqInstall }}</div>
						<div>型号：{{ props.row.eqNorms }}</div>
						<div>技术参数：{{ props.row.eqSkill }}</div>
						<div>功能用途：{{ props.row.eqFunction }}</div>
						<!-- <div>品牌：{{ props.row.eqBrand }}</div> -->
						<!-- <div>单位：{{ props.row. eqUnit }}</div> -->
						<div v-if="props.row.eqCustom" v-for="(item,index) in JSON.parse(props.row.eqCustom)">
							{{item.name}}：{{ item.value }}
						</div>
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip label="设备类型">
					<template slot-scope="props">
						{{props.row.shixunName}}>{{props.row.nextTypeName}}
					</template>
				</el-table-column>
				<el-table-column label="名称" prop="eqName">
				</el-table-column>
				<el-table-column label="规格/型号" prop="eqModel" width="160" show-overflow-tooltip>
				</el-table-column>
				<el-table-column show-overflow-tooltip label="厂家信息">
					<template slot-scope="scope">
						<span style="cursor: pointer;"
							@click="eqFactoryinfoshow = true,eqFactoryinfo(scope.row.eqFactory)">{{scope.row.eqManufacturer}}</span>
					</template>
				</el-table-column>
				<el-table-column label="品牌" prop="eqBrand" width="100" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="单位" prop="eqUnit" width="80" show-overflow-tooltip>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="eqPurchaseRoute" label="购买途径">
					<template slot-scope="props">
						<template v-if="props.row.eqPurchaseRoute.slice(0,4) == 'http'">
							<a :href="props.row.eqPurchaseRoute" target="_blank">点击跳转</a>
						</template>
						<template v-else>
							{{props.row.eqPurchaseRoute}}
						</template>
					</template>
				</el-table-column>
				<el-table-column label="数量" width="100">
					<template slot="header" slot-scope="scope">
						数量
						<template v-if="magnificationtype">
							<i @click="inputkeywordtype =1,inputkeyword ='',magnificationdialog = true"
								class="aleft el-icon-edit"></i>
						</template>
					</template>
					<template slot-scope="props">
						<el-tooltip class="item" effect="dark" :content="props.row.number+''" placement="top">
							<el-input :disabled="$route.query.isUp == 3 ?true:false" @input="inputvaluechange(props,0)"
								onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" :min="1" type="number"
								v-model="props.row.number"></el-input>
						</el-tooltip>
					</template>
				</el-table-column>
				<!-- <el-table-column width="150">
					<template slot="header" slot-scope="scope">
						倍率（倍）
						<template v-if="magnificationtype">
							<i @click="inputkeywordtype =2,inputkeyword ='',magnificationdialog = true"
								class="aleft el-icon-edit"></i>
						</template>
					</template>
					<template slot-scope="props">
						<el-tooltip class="item" effect="dark" :content="props.row.filed1+''" placement="top">
							<el-input :min="0" step="0.1" type="number" @input="inputvaluechange(props,1)"
								v-model="props.row.filed1"></el-input>
						</el-tooltip>

					</template>
				</el-table-column> -->
				<el-table-column width="100" show-overflow-tooltip>
					<template slot="header" slot-scope="scope">
						售价{{unitvalue?'元':'万元'}}
					</template>
					<template slot-scope="props">
						<!-- <template v-if="!isNaN(props.row.filed1)">

							{{

								props.row.filed1>0?parseFloat(props.row.price) * (parseFloat(props.row.filed1)+1):parseFloat(props.row.price) * (1-Math.abs(parseFloat(props.row.filed1)))
								
							}}
						</template> -->
						{{props.row.eqOriginalPrice}}
					</template>
				</el-table-column>
				<el-table-column width="130" show-overflow-tooltip>
					<template slot="header" slot-scope="scope">
						总价<el-switch :disabled="$route.query.isUp == 3 ? true : false" @change="switchchange"
							v-model="unitvalue" active-text="元" inactive-text="万元">
						</el-switch>
					</template>
					<template slot-scope="props">
						{{isNaN(props.row.totalprice)?'':props.row.totalprice}}
					</template>
				</el-table-column>
				<el-table-column label="是否购买" width="140" show-overflow-tooltip>
					<template slot-scope="props">
						<el-switch @change="isbuychange(props.row)" v-model="props.row.isBuy" active-text="已买"
							inactive-text="未买" :disabled="$route.query.isUp == 3 ?false:true">

						</el-switch>
					</template>
				</el-table-column>
				<el-table-column width="80">
					<template slot="header" slot-scope="scope">
						操作
						<template v-if="magnificationtype">
							<i @click="deletearrs" class="aleft el-icon-delete"></i>
						</template>
					</template>
					<template slot-scope="props">
						<el-button :disabled="$route.query.isUp == 3 ?true:false" @click="deletetabledata(props)"
							size="mini" type="primary" icon="el-icon-delete">
						</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div v-if="tableData&&tableData.length>0&&$route.query.isUp!=3" class="formsubmit ">
				<template>
					<div class="childer1">
						<span>合计金额：{{tableDatanumber}}{{unitvalue?'元':'万元'}}</span>

					</div>
					<!-- <div class="childer2">
						<el-button type="primary" @click="opendialog">添加设备</el-button>
					</div> -->
					<div class="childer3">
						<span class="submit submit2" @click="datadetermine">提交</span>
						<!-- <span class="submit" @click="$router.go(-1)">取消</span> -->
					</div>
				</template>
			</div>
			<!-- <div v-else>
				<div class="childer4">
					<el-button type="primary" @click="opendialog">添加设备</el-button>
				</div>
			</div> -->
		</div>
		<!-- <div class="formsubmit formsubmit2" v-if="tableData&&tableData.length>0&&roleId == 2">
			<div class="childer1">
				<span>合计金额：{{tableDatanumber}}{{unitvalue?'元':'万元'}}</span>
				<el-switch @change="switchchange" v-model="unitvalue" active-text="元" inactive-text="万元">
				</el-switch>
			</div>
			<div class="childer2">
				<el-button type="primary" @click="opendialog">添加设备</el-button>
			</div>
			<div class="childer3">
				<span class="submit submit2" @click="datadetermine">提交</span>
				<span class="submit" @click="$router.go(-1)">取消</span>
			</div>
		</div> -->
		<el-dialog  @closed="dialogbodyclose" top="9vh" :close-on-click-modal="false" title="设备列表" :lock-scroll="false"
			:visible.sync="dialogVisible" width="70%" class="dialogs">
			<div v-loading="loadings" class="dialogbody" id="dialogbody">
				<div class="search">
					<div class="left" v-if="fclassification&&fclassification.length>0">
						<!-- <el-cascader placeholder="请选择" v-model="select" :options="fclassification"
							:props="{value:'id',label:'equipmentName',expandTrigger: 'hover'}">
						</el-cascader> -->
						<el-cascader :props="props" v-model="select"  ></el-cascader>
					</div>
					<div class="right">
						<el-input clearable @clear="inputclear" placeholder="请输入内容" v-model="input3"
							class="input-with-select">
							<!-- <el-select v-model="select" slot="prepend" placeholder="请选择">
								<el-option v-for="(item,index) in fclassification" :label="item.equipmentName"
									:value="item.id" :key="index">
								</el-option>
							</el-select> -->
							<el-button slot="append" icon="el-icon-search" @click="keywordclick">按名称</el-button>
							<el-button slot="append" icon="el-icon-search" @click="keywordbymanufacturerclick">按厂家
							</el-button>
						</el-input>
					</div>
				</div>
				<div class="menuList">
					<el-tabs  type="border-card" @tab-click="findtableData" v-model="tabpaneindex">
						<el-tab-pane :label="item" :name="index+''" v-for="(item,index) in tabpanename">
							<el-table v-loadmore="loadMore" height="400" row-key="id" border ref="multipleTable" :data="choicetableData"
								tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
								<el-table-column type="selection" reserve-selection width="55"
									:selectable="checkSelectable">
								</el-table-column>
								<el-table-column type="index" label="序号" width="50">
								</el-table-column>
								<el-table-column type="expand">
									<template slot-scope="props">
										<div>是否订做：{{ props.row.eqCustomMade }}</div>
										<div>发票、税：{{ props.row.eqInvoice }}</div>
										<div>是否安装/安装费：{{ props.row.eqInstall }}</div>
										<div>型号：{{ props.row.eqNorms }}</div>
										<div>技术参数：{{ props.row.eqSkill }}</div>
										<div>功能用途：{{ props.row.eqFunction }}</div>
										<!-- <div>品牌：{{ props.row.eqBrand }}</div> -->
										<!-- <div>单位：{{ props.row. eqUnit }}</div> -->
										<div v-if="props.row.eqCustom"
											v-for="(item,index) in JSON.parse(props.row.eqCustom)">
											{{item.name}}：{{ item.value }}
										</div>
									</template>
								</el-table-column>
								<el-table-column show-overflow-tooltip label="设备类型">
									<template slot-scope="props">
										{{props.row.shixunName}}>{{props.row.nextTypeName}}
									</template>
								</el-table-column>
								<el-table-column prop="eqName" label="名称">
								</el-table-column>
								<el-table-column width="300" prop="eqModel" show-overflow-tooltip label="规格/型号">
								</el-table-column>
								<el-table-column prop="eqFactoryName" show-overflow-tooltip label="厂家名称">
								</el-table-column>
								<el-table-column prop="eqBrand" show-overflow-tooltip label="品牌">
								</el-table-column>
								<el-table-column prop="eqUnit" show-overflow-tooltip label="单位">
								</el-table-column>
								<el-table-column show-overflow-tooltip prop="eqPurchaseRoute" label="购买途径">
									<template slot-scope="props">
										<template v-if="props.row.eqPurchaseRoute.slice(0,4) == 'http'">
											<a :href="props.row.eqPurchaseRoute" target="_blank">点击跳转</a>
										</template>
										<template v-else>
											{{props.row.eqPurchaseRoute}}
										</template>
									</template>
								</el-table-column>
								<el-table-column prop="eqOriginalPrice" show-overflow-tooltip label="原始单价" width="120">
								</el-table-column>
							</el-table>
							<div class="pagination" v-if="totalnumber>50">
								<el-pagination :page-size="50" :current-page="pagenum"
									@current-change="paginationchange" background layout="prev, pager, next"
									:total="totalnumber">
								</el-pagination>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="submitForm">确 定</el-button>
				</span>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="magnificationdialog" :close-on-click-modal="false">
			<el-input v-if="inputkeywordtype == 2" :min="-1" step="0.1" type="number" v-model="inputkeyword"></el-input>
			<el-input v-else :min="1" step="1" type="number" v-model="inputkeyword">
			</el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="magnificationdialog = false">取 消</el-button>
				<el-button type="primary" @click="magnificationSubmit">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="eqFactoryinfoshow" width="70%">
			<div class="infobody">
				<div class="infos">
					<span>厂家名称：</span><span>{{eqFactoryinfodata.ftyName? eqFactoryinfodata.ftyName: '无'}}</span>
				</div>
				<div class="infos">
					<span>厂址：</span><span>{{eqFactoryinfodata.ftyAddress? eqFactoryinfodata.ftyAddress: '无'}}</span>
				</div>
				<div class="infos">
					<span>电话：</span><span>{{eqFactoryinfodata.ftyTel? eqFactoryinfodata.ftyTel: '无'}}</span>
				</div>
				<div class="infos">
					<span>法人姓名：</span><span>{{eqFactoryinfodata.ftyLegalUser? eqFactoryinfodata.ftyLegalUser: '无'}}</span>
				</div>
				<div class="infos">
					<span>联系电话：</span><span>{{eqFactoryinfodata.ftyFiled4? eqFactoryinfodata.ftyFiled4: '无'}}</span>
				</div>
				<div class="infos">
					<span>产品类型：</span><span>{{eqFactoryinfodata.ftyFiled1? eqFactoryinfodata.ftyFiled1: '无'}}</span>
				</div>
				<div class="infos">
					<span>营业范围：</span><span>{{eqFactoryinfodata.ftyBusiness? eqFactoryinfodata.ftyBusiness: '无'}}</span>
				</div>
				<div class="infos">
					<span>公司简介：</span><span>{{eqFactoryinfodata.ftyContent? eqFactoryinfodata.ftyContent: '无'}}</span>
				</div>
				<div class="infos">
					<span>公司官网地址：</span><span>{{eqFactoryinfodata.ftyUrl? eqFactoryinfodata.ftyUrl: '无'}}</span>
				</div>
				<div v-if="eqFactoryinfodata.ftyCertificate"><span>营业执照</span>
					<span>
						<viewer :z-index="99999" :images="eqFactoryinfodata.ftyCertificate.split(',')">
							<el-carousel class="imgbanner" trigger="click" height="400px" :autoplay="false"
								arrow="always">
								<el-carousel-item v-for="(item,index) in eqFactoryinfodata.ftyCertificate.split(',')"
									:key="'pl'+index">
									<span class="bannerimg"><img :src="item" alt=""></span>
								</el-carousel-item>
							</el-carousel>
						</viewer>
					</span>
				</div>
				<div v-if="eqFactoryinfodata.ftyQualified"><span>合格证书</span>
					<span>
						<viewer :z-index="99999" :images="eqFactoryinfodata.ftyQualified.split(',')">
							<el-carousel class="imgbanner" trigger="click" height="400px" :autoplay="false"
								arrow="always">
								<el-carousel-item v-for="(item,index) in eqFactoryinfodata.ftyQualified.split(',')"
									:key="'pl'+index">
									<span class="bannerimg"><img :src="item" alt=""></span>
								</el-carousel-item>
							</el-carousel>
						</viewer>
					</span>
				</div>
				<div v-if="eqFactoryinfodata.ftyLogo"><span>公司logo</span>
					<span>
						<viewer :z-index="99999" :images="eqFactoryinfodata.ftyLogo.split(',')">
							<el-carousel class="imgbanner" trigger="click" height="400px" :autoplay="false"
								arrow="always">
								<el-carousel-item v-for="(item,index) in eqFactoryinfodata.ftyLogo.split(',')"
									:key="'pl'+index">
									<span class="bannerimg"><img :src="item" alt=""></span>
								</el-carousel-item>
							</el-carousel>
						</viewer>
					</span>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['reload'],
		data() {
			let _this = this
			return {
				props: {
					value: 'id',
					label: 'equipmentName',
					lazy: true,
					lazyLoad(node, resolve) {
						console.log(node)
						setTimeout(() => {
							if (node.level == 0) {
								resolve(_this.fclassification);
							} else if (node.level == 1) {
								if (node.data.id > 1) {
									_this.$post('equipment/queryEquipmentNextType', {
										proId: node.data.id
									}).then((res) => {
										let datas = res
										if(res&&res.length>0){
											res.unshift({
												id:0,
												nextTypeName:'全部'
											})
										}else{
											datas = [{
												id:0,
												nextTypeName:'全部'
											}]
										}
										const secondLevel = datas.map(val => ({
											id: val.id,
											equipmentName: val.nextTypeName,
											leaf: node.level >= 1
										}));
										resolve(secondLevel);
									})
								}else{
									resolve([])
								}
							} else {
								return false
							}
						}, 1000)
				
					}
				},
				eqFactoryinfoshow: false,
				eqFactoryinfodata: '',
				isbuydata: '',
				isbuyvalue: '',
				loadings: false,
				equipmentlist: [],
				comparerawdata: [],
				tabpanename: ['低档商品', '中档商品', '高档商品', '其他'],
				tabpaneindex: '0',
				cclassification: [],
				fclassification: [{
					id: 0,
					equipmentName: '全部',
					children: []
				}],
				select: [],
				input3: "",
				offsetHeightdata: '',
				buttonshow: false,
				buttonshow2: false,
				inputkeywordtype: 0,
				totalnumber: 0,
				userId: sessionStorage.getItem('userId'),
				roleId: sessionStorage.getItem('roleId'),
				inputFormshixunId: '',
				FormshixunId: 1,
				times: '',
				unitvalue: true,
				dialogVisible: false,
				choicetableData: [],
				tableData: [],
				copytableData: [
					[],
					[],
					[],
					[],
					[]
				],
				ruleForm: {
					poName: '',
					poContext: '',
				},
				rules: {
					poName: [{
							required: true,
							message: '请输入方案名称',
							trigger: 'blur'
						}
						// ,
						// {
						// 	min: 3,
						// 	max: 5,
						// 	message: '长度在 3 到 5 个字符',
						// 	trigger: 'blur'
						// }
					],
					poContext: [{
						required: true,
						message: '请输入方案简介',
						trigger: 'blur'
					}]
				},
				equipmentTypeList: '',
				inputkeyword: '',
				magnificationtype: false,
				magnificationdialog: false,
				clickprogrammedatas: [],
				comparerawdatass: [],
				tableDatanumber: '',
				datalisttype: 0,
				pagenum: 1,
				grade: 1,
				trainingtypedata:[{
					id: 0,
					equipmentName: '全部',
				}]
			};
		},
		created() {
			this.select =[this.fclassification[0].id]
			if (this.$route.query.version) {
				this.getEquipmentequipment()
			} else {

			}
			this.getEquipmentTypeList()

		},
		mounted() {
			// window.addEventListener('scroll', this.scrolltop,true)
			// this.times = setInterval(()=>{
			// 	console.log("12")
			// },300000);
			// this.$once('hook:beforeDestroy', () => {           
			//     clearInterval(this.times);                                   
			// })
			// this.$nextTick(()=>{
			// 	setTimeout(()=>{
			// 		let rect = document.getElementById('childer');
			// 		let rect2 = document.getElementById('aswwqa');
			// 		let data = rect2.offsetHeight-rect.offsetHeight;
			// 		this.offsetHeightdata = data
			// 	},2000)

			// })
		},
		methods: {
			loadMore() {
				var tableNum = this.choicetableData.length;
				let a = this.equipmentlist.slice(tableNum, tableNum + 50);
				if (a.length === 0) {
					// this.$message.warning('已经到底啦')
				}else{
					a.map(item => {
						this.choicetableData.push(item);
					});
				}
			},
			//获取二级分类
			async gettrainingtype() {
				await this.$get('equipment/queryEquipmentNextType').then((res) => {
					// console.log(trainingtypedata)
					// console.log(res)
					if (res && res.length > 0) {
						res.forEach((e, m) => {
							e.equipmentName = e.nextTypeName
							this.trainingtypedata.push(e)
						})
					}
				})
				this.getEquipmentTypeList()
			},
			eqFactoryinfo(id) {
				this.$post('programme/selectByPrimaryKey', {
					id: id
				}).then((res) => {
					this.eqFactoryinfodata = res
				})
			},
			isbuychange(val) {
				console.log(val)
				let gh = 0
				val.isBuy ? gh = 1 : gh = 2
				console.log(gh)
				this.$post('programme/equipmentBuy', {
					id: this.$route.query.programmeId,
					version: this.$route.query.version,
					equipmentId: val.equipmentId,
					userId: this.userId,
					buy: gh
				}).then(() => {
					this.$message({
						message: '操作成功',
						type: 'success'
					});
					this.getEquipmentequipment()
				})
			},
			//选择设备弹窗关闭时
			dialogbodyclose() {
				console.log("54")
				for (var i = 0; i < this.$refs.multipleTable.length; i++) {
					this.$refs.multipleTable[i].clearSelection()
				}
			},
			//选择设备时切换分类时清除已选择的
			selectchanges(val) {
				this.copytableData = [
					[],
					[],
					[],
					[],
					[]
				]
				this.$nextTick(() => {
					for (var i = 0; i < this.$refs.multipleTable.length; i++) {
						this.$refs.multipleTable[i].clearSelection()
					}
				})
			},
			// 批量删除
			deletearrs() {
				let that = this
				that.$confirm('此操作将删除设备, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose(action, instance, done) {
						if (action == "confirm") {
							instance.$refs.confirm.$el.onclick = function(e) {
								e = e || window.event
								console.log(e.detail)
								if (e.detail != 0) {
									done()
								}
							}
						} else {
							done()
						}
					}
				}).then(() => {
					that.clickprogrammedatas.forEach((q, w) => {
						that.tableData.forEach((e, m) => {
							if (e.id == q.id) {
								that.tableData.splice(m, 1)
							}
						})
					})
					this.unitvalue = true
				}).catch(() => {

				});
				that.$nextTick(() => {
					that.$set(that.tableData)

				})
			},
			findtableData(val) {
				if (!this.input3) {
					this.datalisttype = 0
				}
				this.grade = parseInt(val.paneName) + 1
				this.pagenum = 1
				this.loadings = true
				if (this.datalisttype == 0) {
					this.getEquipmentList()
				} else if (this.datalisttype == 1) {
					this.keywordsearch()
				} else {
					this.keywordbymanufacturer()
				}
			},
			scrolltop() {
				let topdata = document.getElementById('childer').scrollTop
				let topdata2 = document.getElementById('dialogbody')
				if (topdata2) {
					if (topdata2.scrollTop > 100) {
						this.buttonshow2 = true
					} else {
						this.buttonshow2 = false
					}
				}
				if (topdata > 150) {
					this.buttonshow = true
				} else if (topdata < 100) {
					this.buttonshow = false
				}

			},
			inputshixunIdchange(val) {
				this.inputFormshixunId = val
			},
			//获取设备类型
			async  getEquipmentTypeList() {
				this.cclassification = []
				await this.$post('equipment/getEquipmentTypeList').then((res) => {
					if (res && res.length > 0) {
						res.forEach((e, m) => {
							this.fclassification.push(e)
						})
						this.cclassification = res
					}
				})
				this.$nextTick(() => {

				})
			},
			keywordclick() {
				this.tabpaneindex = '0'
				this.datalisttype = 1
				this.pagenum = 1
				this.grade = 1
				if (this.input3) {

					this.keywordsearch()
				} else {
					// this.FormshixunId = this.select
					this.getEquipmentList()
					// this.$message({
					// 	type: 'warning',
					// 	message: '请输入关键字!'
					// });
				}
			},
			//检索
			keywordbymanufacturerclick() {
				this.datalisttype = 2
				this.grade = 1
				this.pagenum = 1
				this.tabpaneindex = '0'
				if (this.input3) {
					this.keywordbymanufacturer()
				} else {
					// this.FormshixunId = this.select
					this.getEquipmentList()
				}
			},
			async keywordbymanufacturer() {
				this.loadings = true
				this.choicetableData = []
				this.totalnumber = 0
				await this.$post('equipment/getEquipmentListByFacturer', {
					shixunId: this.select[0],
					nextTypeId: this.select[1] ? this.select[1] : 0,
					userId: this.userId,
					eqManufacturer: this.input3,
					roleId: this.roleId,
					pagenum: this.pagenum,
					pagesize: 50,
					filed4: this.grade
				}).then((res) => {
					this.loadings = false
					if (res.length > 50) {
						this.choicetableData = res.slice(0, 50)
					} else {
						this.choicetableData = res
					}
					this.equipmentlist = res
				}).catch((err) => {
					this.choicetableData = []
					this.loadings = false
				})

				this.$nextTick(() => {
					if (this.choicetableData && this.choicetableData.length > 0 && this.tableData && this
						.tableData.length > 0) {
						for (let g in this.choicetableData) {
							for (let t in this.tableData) {
								if (this.choicetableData[g].id == this.tableData[t].id) {
									console.log("2")
									this.choicetableData[g].isModify = '是'
									break
								} else {
									this.choicetableData[g].isModify = '否'
								}
							}
						}

					} else {
						console.log("4")
						this.choicetableData.forEach((e, m) => {
							e.number = 1
							e.isModify = '否'
						})
					}
					this.loadings = false
					this.$set(this.choicetableData)
				})
			},
			async keywordsearch() {
				this.loadings = true
				this.choicetableData = []
				this.totalnumber = 0
				await this.$post('equipment/getEquipmentListByName', {
					shixunId: this.select[0],
					nextTypeId: this.select[1] ? this.select[1] : 0,
					userId: this.userId,
					name: this.input3,
					roleId: this.roleId,
					filed4: this.grade
				}).then((res) => {
					this.loadings = false
					if (res.length > 50) {
						this.choicetableData = res.slice(0, 50)
					} else {
						this.choicetableData = res
					}
					this.equipmentlist = res
				}).catch((err) => {
					this.choicetableData = []
					this.loadings = false
				})
				this.$nextTick(() => {
					if (this.choicetableData && this.choicetableData.length > 0 && this.tableData && this
						.tableData.length > 0) {
						for (let g in this.choicetableData) {
							console.log(this.choicetableData[g])
							for (let t in this.tableData) {
								if (this.choicetableData[g].id == this.tableData[t].id) {
									this.choicetableData[g].isModify = '是'
									break
								} else {
									this.choicetableData[g].isModify = '否'
								}
							}
						}

					} else {
						this.choicetableData.forEach((e, m) => {
							e.number = 1
							e.isModify = '否'
						})
					}
					this.loadings = false
					this.$set(this.choicetableData)
				})
			},
			//清除搜索
			inputclear() {
				this.tabpaneindex = '0'
				this.datalisttype = 0
				this.grade = 1
				this.pagenum = 1
				this.select =[this.fclassification[0].id]
				this.FormshixunId = 1
				this.getEquipmentList()

			},
			//分页
			paginationchange(val) {
				this.pagenum = val
				if (this.datalisttype == 0) {
					this.getEquipmentList()
				} else if (this.datalisttype == 2) {
					this.keywordbymanufacturer()
				} else {
					this.keywordsearch()
				}

			},
			inputvaluechange(val, type) {
				let a = val.row.number
				let b = val.row.eqOriginalPrice
				if (this.unitvalue) {
					this.tableData[val.$index].totalprice = (parseFloat(a) * parseFloat(b)).toFixed(2)
				} else {
					this.tableData[val.$index].totalprice = (parseFloat(a) * parseFloat(b)).toFixed(4)
				}
				this.$set(this.tableData)
				this.numberover()
			},
			switchchange(val) {
				let ghj = 0
				let that = this
				let tge = []
				let fdg = val ? 0 : 1
				if (val) {
					that.tableData.forEach((e, m) => {
						e.unit = 0
						e.totalprice = (parseFloat(e.totalprice) * 10000).toFixed(2)
						e.eqOriginalPrice = (parseFloat(e.eqOriginalPrice) * 10000).toFixed(2)
						ghj += parseFloat(e.totalprice)
						tge.push(e)
					})
					that.tableDatanumber = ghj.toFixed(2)
				} else {
					that.tableData.forEach((e, m) => {
						e.unit = 1
						e.totalprice = (parseFloat(e.totalprice) / 10000).toFixed(4)
						e.eqOriginalPrice = (parseFloat(e.eqOriginalPrice) / 10000).toFixed(4)
						ghj += parseFloat(e.totalprice)
						tge.push(e)
					})
					that.tableDatanumber = ghj.toFixed(4)
				}
				that.$nextTick(() => {
					console.log(that.tableData[0])
					that.tableData = tge
					that.$set(that.tableData)
				})
			},
			magnificationSubmit() {
				if (!this.inputkeyword) {
					this.$message({
						message: '请填写信息',
						type: 'warning'
					});
					this.inputkeyword = ''
				} else if (this.inputkeyword && this.inputkeywordtype == 1 && this.inputkeyword < 0) {
					this.$message({
						message: '数量最少为零',
						type: 'warning'
					});
					this.inputkeyword = ''
				} else if (this.inputkeyword && this.inputkeywordtype == 2 && this.inputkeyword < -1) {
					this.$message({
						message: '倍率输入不正确',
						type: 'warning'
					});
					this.inputkeyword = ''
				} else {
					this.clickprogrammedatas.forEach((z, x) => {
						this.tableData.forEach((e, m) => {
							if (e.equipmentId == z.equipmentId) {
								if (this.unitvalue) {
									e.totalprice = (this.inputkeyword * e.eqOriginalPrice).toFixed(2)
								} else {
									e.totalprice = (this.inputkeyword * e.eqOriginalPrice).toFixed(4)
								}
								e.number = this.inputkeyword
							}
						})
					})
					this.numberover()
					this.clickprogrammedatas = []
					this.magnificationtype = false
					this.$refs.overtable.clearSelection();
					this.magnificationdialog = false
				}
				this.$nextTick(() => {
					this.$set(this.tableData)
				})
			},
			programmeselection(val) {
				this.clickprogrammedatas = val
				if (val && val.length >= 2) {
					this.magnificationtype = true
				} else {
					this.magnificationtype = false
				}
			},
			async getEquipmentequipment() {
				let that = this
				that.loadings = true
				await that.$post('programme/getProgrammeDetail', {
					programmeId: that.$route.query.programmeId,
					version: that.$route.query.version
				}).then((res) => {
					res.forEach((e, m) => {
						e.isBuy == 2 ? e.isBuy = false : e.isBuy = true
						if (m == 0) {
							if (e.unit == 0) {
								that.unitvalue = true
							} else {
								that.unitvalue = false
							}
						}
						e.id = e.equipmentId
						e.totalprice = 0
						if (that.$route.query.isUp == 0) {
							e.eqOriginalPrice = parseFloat(e.initialPrice) * parseFloat(e.eqTimesRate)
						} else {
							e.eqOriginalPrice = parseFloat(e.initialPrice) * parseFloat(e.filed1)
						}
						if (e.unit == 1) {
							e.totalprice = ((parseFloat(e.eqOriginalPrice) * parseFloat(e.number)) /
								10000).toFixed(4)
							e.eqOriginalPrice = (parseFloat(e.eqOriginalPrice) / 10000).toFixed(4)
						} else {
							e.totalprice = (parseFloat(e.eqOriginalPrice) * parseFloat(e.number))
								.toFixed(2)
							// e.eqOriginalPrice = e.eqOriginalPrice.toFixed(2)
						}
						that.comparerawdatass.push({
							equipmentId: e.equipmentId,
							userId: that.userId,
							price: e.eqOriginalPrice,
							number: e.number,
							filed1: e.eqTimesRate
						})
					})
					that.tableData = res
				})
				that.numberover()
				that.loadings = false
				console.log(that.comparerawdatass)
			},
			shixunIdchange(val) {
				this.pagenum = 1
				this.datalisttype = 0
				this.inputFormshixunId = this.fclassification[0].id
				this.getEquipmentList(val)
			},
			async getEquipmentList(id) {
				this.totalnumber = 0
				this.choicetableData = []
				this.loadings = true
				await this.$post('equipment/queryEquipmentList', {
					proId: this.select[0],
					nextTypeId: this.select[1] ? this.select[1] : 0,
					filed4: this.grade
				}).then((res) => {
					let datas = res
					this.tableData = this.tableData.filter(res => {
						return res != "undefined"
					})
					if (datas && datas.length > 0 && this.tableData && this.tableData.length > 0) {
						for (let g in datas) {
							for (let t in this.tableData) {
								datas[g].number = 1
								if (datas[g].id == this.tableData[t].id) {
									datas[g].isModify = '是'
									break
								} else {
									datas[g].isModify = '否'
								}
							}
						}

					} else {
						datas.forEach((e, m) => {
							e.number = 1
							e.isModify = '否'
						})
					}
					if (datas.length > 50) {
						this.choicetableData = datas.slice(0, 50)
					} else {
						this.choicetableData = datas
					}
					this.equipmentlist = datas
				}).catch((err) => {
					this.choicetableData = []
				})
				this.$nextTick(() => {
					this.$set(this.choicetableData)
					this.loadings = false
				})
			},
			numberover(val) {
				let ghj = 0
				if (this.tableData && this.tableData.length > 0) {
					this.tableData.forEach((e, m) => {
						ghj += parseFloat(e.totalprice)
					})
					if (ghj.toString().indexOf(".") == -1) {
						ghj = ghj + '.00'
					} else {
						if (this.tableData[0].unit == 1) {
							ghj = ghj.toFixed(4)
						} else {
							ghj = ghj.toFixed(2)
						}
					}
					this.tableDatanumber = ghj
					// if(this.tableData[0].unit == 0){
					// 	this.tableDatanumber = parseFloat(ghj).toFixed(2)
					// }else{
					// 	this.tableDatanumber = parseFloat(ghj).toFixed(4)
					// }
				} else {

				}
			},
			submitForm() {
				let fg = 0
				let lengths = this.copytableData.length
				this.copytableData.forEach((r, m) => {
					if (r && r.length > 0) {
						r.forEach((e, m) => {
							e.number = 1
							e.isBuy = false
							e.totalprice = 0
							if (this.unitvalue) {
								e.totalprice = (parseFloat(e.eqOriginalPrice) * parseFloat(e.number))
									.toFixed(2)
								e.eqOriginalPrice = parseFloat(e.eqOriginalPrice).toFixed(2)
							} else {
								e.totalprice = ((parseFloat(e.eqOriginalPrice) * parseFloat(e.number)) /
										10000)
									.toFixed(4)
								e.eqOriginalPrice = (parseFloat(e.eqOriginalPrice) / 10000).toFixed(4)
							}
							this.tableData.push(e)
						})
					} else {
						fg++
					}
				})
				console.log(fg)
				if (fg == lengths) {
					this.$message({
						message: '请先选择设备',
						type: 'warning'
					});
				} else {
					this.$set(this.tableData)
					this.numberover()
					this.dialogVisible = false
					// console.log(this.tableData)
				}
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			checkSelectable(row) {
				return row.isModify == '否'
			},
			handleSelectionChange(val) {
				console.log(val)
				this.copytableData[parseFloat(this.tabpaneindex)] = []
				if (val && val.length > 0) {
					val.forEach((e, m) => {
						this.copytableData[parseFloat(this.tabpaneindex)].push(e)
					})
				}
				console.log(this.copytableData)
			},
			opendialog() {
				let that = this
				that.tabpaneindex = '0'
				that.datalisttype = 0
				that.grade = 1
				that.input3 = ''
				that.FormshixunId = 1
				this.select =[this.fclassification[0].id]
				that.pagenum = 1
				that.dialogVisible = true
				that.copytableData = [
					[],
					[],
					[],
					[],
					[]
				]
				that.choicetableData = []
				that.getEquipmentList()
				// if (that.tableData && that.tableData.length > 0) {
				// 	that.tableData.forEach((q, w) => {
				// 		console.log(q.id)
				// 		that.choicetableData.forEach((a, s) => {
				// 			console.log(a.id)
				// 			if (a.id == q.id) {
				// 				a.isModify = '否'
				// 			}
				// 		})
				// 	})
				// }
				// that.$nextTick(() => {
				// 	that.$refs.multipleTable.clearSelection()
				// })
			},
			datadetermine() {
				let that = this
				let jsonStrs = []
				let fordatas = that.tableData
				let totalPrices = 0
				let number = 0
				let dfg = ''
				that.unitvalue ? dfg = 0 : dfg = 1
				that.numberover()
				fordatas = fordatas.filter(res => {
					return res != "undefined"
				})
				console.log(fordatas)
				for (let i in fordatas) {
					let gert = ''
					if (fordatas[i]) {
						if (fordatas[i].number < 1) {
							that.$message({
								message: fordatas[i].eqName + '数量值格式不对（数量值为大于1的数字）',
								type: 'warning'
							});
							return false
						}
						// fordatas[i].eqOriginalPrices = 0
						// fordatas[i].filed1 > 0 ? fordatas[i].eqOriginalPrices = (parseFloat(fordatas[i].price) + ((
						// 		parseFloat(
						// 			fordatas[i].filed1) / 100) * parseFloat(fordatas[i].price))) : fordatas[i]
						// 	.eqOriginalPrices =
						// 	(parseFloat(fordatas[i].price) - (Math.abs(parseFloat(fordatas[i].filed1)) / 100 * parseFloat(
						// 		fordatas[i].price)))
						jsonStrs.push({
							equipmentId: fordatas[i].id,
							userId: that.userId,
							price: fordatas[i].eqOriginalPrice,
							number: parseFloat(fordatas[i].number),
							filed1: fordatas[i].eqTimesRate
						})
					}
				}
				// console.log(JSON.stringify(jsonStrs))
				// console.log(JSON.stringify(that.comparerawdatass))
				// console.log(JSON.stringify(jsonStrs) == JSON.stringify(that.comparerawdatass))
				if (JSON.stringify(jsonStrs) == JSON.stringify(that.comparerawdatass)) {
					that.$message({
						message: '未进行任何操作禁止重复提交',
						type: 'error'
					});
				} else {
					that.$post('programme/addEquipment', {
						programmeId: that.$route.query.programmeId,
						totalPrice: that.tableDatanumber,
						jsonStr: JSON.stringify(jsonStrs),
						unit: !that.unitvalue ? 1 : 0,
						version: that.$route.query.newversion ? that.$route.query.newversion : that.$route.query
							.version
					}).then((res) => {
						that.$message({
							message: '操作成功',
							type: 'success'
						});
						that.$router.push('page3')
					})
				}
			},
			deletetabledata(props) {
				let that = this
				that.$confirm('此操作将删除该设备, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose(action, instance, done) {
						if (action == "confirm") {
							instance.$refs.confirm.$el.onclick = function(e) {
								e = e || window.event
								console.log(e.detail)
								if (e.detail != 0) {
									done()
								}
							}
						} else {
							done()
						}
					}
				}).then(() => {
					that.tableData.forEach((a, s) => {
						if (a.id == props.row.id) {
							that.tableData.splice(s, 1)
						}
					})
					that.tableData = that.tableData.filter(res => {
						return res != "undefined"
					})
					console.log(that.tableData)
					that.$set(that.tableData)
					that.numberover()
				}).catch(() => {

				});
				that.$nextTick(() => {

				})
			}
		},
		deactivated() {
			window.removeEventListener('scroll', this.scrolltop)
		}
	}
</script>

<style scoped lang="less">
	.bannerimg {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;

		>img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.infobody {
		height: 65vh;
		overflow-y: auto;

		.infos {
			margin-bottom: 20px;

			>span {
				font-size: 16px;
				color: #999;
			}

			>:first-child {
				font-size: 18px;
				color: #666;
			}
		}
	}

	#add {
		position: relative;
		width: 100%;
	}

	.formsubmit {
		padding: 40px 10px;
		margin: 20px 0 0 0;
		border: 1px solid #eee;

		span {
			display: inline-block;
		}

		.childer1 {
			text-align: right;

			>* {
				display: inline-block;
				vertical-align: middle;
			}

			>span {
				margin-right: 40px;
				font-size: 20px;
				color: #ff0808;
			}
		}

		.childer2 {
			padding: 30px 0 80px 0;
		}

		.childer3 {
			>* {
				cursor: pointer;
			}

			.submit {
				border: 1px solid #eee;
				color: #666;
				border-radius: 8px;
				padding: 10px 100px;
				font-size: 20px;
			}

			.submit2 {
				background-color: #409EFF;
				margin-right: 10%;
				border-color: #409EFF;
				color: #fff;
			}
		}
	}

	.formsubmit2 {
		position: fixed;
		border: 1px solid #eee;
		padding: 40px 10px;
		box-sizing: border-box;
		width: 84%;
		bottom: 0;
		background-color: #fdfdfd;
		z-index: 999;
		text-align: center;
	}

	.dialogbody {
		// height: 73vh;
		overflow-y: auto;
		position: relative;
		width: 100%;

		.menuList {
			// margin: 0 0 60px 0;
		}
	}

	.dialog-footer {
		display: inline-block;
		width: 100%;
		text-align: center;
		margin: 20px 0;
	}

	.dialog-footers {
		position: fixed;
		bottom: 80px;
		width: 68%;
		background-color: #fdfdfd;
		padding: 15px 0;
		z-index: 9999;
		margin: 0;
	}

	.numbers {
		// >* {
		// 	display: inline-block;
		// 	vertical-align: middle;
		// }

		// >span {
		// 	margin-right: 130px;
		// 	font-size: 20px;
		// 	color: #ff0808;
		// }

		padding: 40px 0;

		text-align: right;

	}

	.formdatas {

		margin-top: 80px;
	}

	.add {
		margin: 60px 0 0 0;
		text-align: center;

		.version {
			text-align: left;
			padding: 0 0 20px 0;
			font-size: 16px;


		}

		.versions {
			button {
				margin-left: 120px;
			}
		}
	}

	.add2 {
		margin: 80px 0 360px 0;
	}

	.addssa {
		margin: 80px 0;
	}

	.textinput {
		margin: 20px 0;
		width: 80%;
	}

	.pagination {
		text-align: center;
		padding: 40px 0 0 0;
	}
	.search {
		margin: 0 0 20px 0;
		>div{
			display: inline-block;
			vertical-align: middle;
		}
		>:last-child{
			width: 77%;
		}
	}
</style>
<style>
	.dialogs .el-input-group__prepend {
		width: 16%;
	}

	.dialogs .input-with-select {
		/* margin: 0 0 20px 0; */
		width: 80%;
	}

	.unit {
		margin-right: 20px;
	}

	.el-checkbox__inner {
		border-color: #999;
	}
</style>
